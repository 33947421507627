import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b87fbb7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "submitForm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OrganizeSelect = _resolveComponent("OrganizeSelect");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_form = _resolveComponent("el-form");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => _ctx.show = $event),
    title: _ctx.title,
    onConfirmOk: _ctx.onConfirm,
    width: "600px"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
      ref: "ruleForm",
      rules: _ctx.rules,
      model: _ctx.formData,
      "label-width": "98px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "所属单位",
        prop: "schoolId"
      }, {
        default: _withCtx(() => [_createVNode(_component_OrganizeSelect, {
          modelValue: _ctx.searchValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.searchValue = $event),
          activeValue: _ctx.activeValue,
          type: 1,
          isDoubleClick: true,
          isSpecify: 'school',
          isClick: false,
          placeholder: "请输入单位",
          onHandleDoubleClick: _ctx.handleDoubleClick
        }, null, 8, ["modelValue", "activeValue", "onHandleDoubleClick"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "任务名称",
        prop: "courseName"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.courseName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.formData.courseName = $event),
          placeholder: "请输入任务名称"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "任务类型",
        required: "",
        prop: "type"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio, {
          modelValue: _ctx.formData.type,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.formData.type = $event),
          label: 1
        }, {
          default: _withCtx(() => [_createTextVNode("必修")]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_radio, {
          modelValue: _ctx.formData.type,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.formData.type = $event),
          label: 2
        }, {
          default: _withCtx(() => [_createTextVNode("选修")]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["rules", "model"])])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}