import { defineComponent, toRefs, reactive, defineAsyncComponent, computed, watch, nextTick } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import API from "@/api/education/index";
import { useMessage } from "@/hooks/web/useMessage";
import { useDebounce } from '@/hooks/core/useDebounce';
export default defineComponent({
  components: {
    Eldialog,
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue"))
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    schoolInfo: {
      type: Object,
      default: null
    }
  },
  emits: ["update:visible", "handleSuccess"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      value: '',
      searchValue: '',
      activeValue: null,
      ruleForm: null,
      formData: null,
      // formData: null as any,
      title: '新增任务',
      gradeOptions: [],
      rules: {
        courseName: [{
          required: true,
          message: "请输入任务名称",
          type: 'string',
          trigger: 'change'
        }, {
          min: 1,
          max: 10,
          message: '长度在 1 到 10 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '任务名称不能带有特殊符号'
        }],
        schoolId: [{
          required: true,
          message: '请选择所属单位',
          trigger: 'blur'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            try {
              let refMsg = '';
              let refFlag = 0;
              if (props.info === null) {
                const {
                  msg,
                  code
                } = await API.courseAdd(refData.formData);
                refMsg = msg;
                refFlag = code;
              } else {
                const {
                  msg,
                  code
                } = await API.courseUpdate({
                  courseId: props.info.courseId,
                  ...refData.formData
                });
                refMsg = msg;
                refFlag = code;
              }
              if (refFlag !== 0) throw refMsg;
              message.success('成功');
              emit("update:visible", false);
              emit("handleSuccess");
            } catch (error) {
              message.error(error);
              console.log(error);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleDoubleClick(data) {
        if (data.type === 'school') {
          refData.formData.schoolId = data.id;
        } else {
          message.error('请选择单位！！！');
        }
        console.log(refData.formData.schoolId);
      }
    });
    // 获取数据
    function getData() {
      Object.keys(props.info).forEach(key => {
        if (refData.formData[key] !== undefined) {
          refData.formData[key] = props.info[key];
        }
      });
      refData.searchValue = props.info.schoolName;
    }
    // 初始化
    function init() {
      refData.formData = {
        courseName: null,
        type: 1,
        schoolId: null
      };
      refData.searchValue = '';
    }
    // 初始化表单
    init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    watch(() => props.visible, val => {
      if (val) {
        init();
        if (props.info !== null) {
          refData.title = '修改任务';
          nextTick(() => {
            init();
            refData.ruleForm.clearValidate();
            getData();
          });
        } else {
          refData.title = '新增任务';
          nextTick(() => {
            init();
            refData.ruleForm.clearValidate();
          });
          refData.activeValue = props.schoolInfo.activeValue;
          if (props.schoolInfo.id !== null) {
            nextTick(() => {
              refData.searchValue = props.schoolInfo.name;
              refData.formData.schoolId = props.schoolInfo.id;
            });
          }
        }
      }
    }, {
      immediate: true
    });
    const [onConfirm] = useDebounce(refData.confirm, 300);
    return {
      ...toRefs(refData),
      show,
      onConfirm
    };
  }
});